import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { node } from 'prop-types';
import {
  fetchAllDetails, 
  fetchAS
} from 'store/slices/account';
import {
  Block,
  Flex,
  FlexColumn,
  FlexWrapper,
  Modal,
  Overflow,
  TextAccentSmall,
  TextBlack,
  TextBlackBig,
  TextLight,
  TitleBlack,
  Loader,
  StyledSelect,
  TextBlackSmallThin,
  SecondaryBtnHigh,
  TextBlackSmall,
  TextBlackThin
} from 'base/styled';
import styled from 'styled-components';
import radio from 'assets/images/radio.svg';
import { ReactComponent as Current} from 'assets/images/current.svg';
import { ReactComponent as PersonalizationIcon} from 'assets/images/personalization_icon.svg';
import { ReactComponent as VisaIcon} from 'assets/images/visa.svg';
import { ReactComponent as Image} from 'assets/images/affiliate.svg';
import { ReactComponent as Link} from 'assets/images/link.svg';
import alert from 'assets/images/alert.png';
import radioactive from 'assets/images/radio_active.svg';
import Success from 'base/components/Success';
import Payment from './PaymentForm';
import Deactivate from './DeactivateNew';
import Responsive from 'context/responsive';
import SuccessSubscribe from 'base/components/SuccessSubscribe';
import network from 'base/network';
import SelectDeactivate from './Select';
import PaidPersonalization from './PaidPersonalization';
import SubscribeForm from './SubscribeForm';
import Discount from './Discount';
import img from 'assets/images/check.svg';

const Company = styled.div`
  padding: 30px 7px 30px 70px;
  width: 815px;
  padding-right: 22px;
  h1 {
    text-align: left;
  }
  .button {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
  .deactivate {
    margin-top: 43px;
    text-align: center;
  }
  .hidden {
    height: 898px;
    padding: 0 5px;
    overflow-y: hidden;
    transition: .4s;
  }
  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
    .left {
      text-align: left;
    }
    .card {
      box-shadow: none;
      background: inherit;
      padding: 0
    }
    .deactivate {
      text-align: center;
    }
    .hidden {
      height: 1132px;
    }
    h1 {
      padding: 30px 0 10px;
      font-size: 26px;
    }
    padding-bottom: 100px;
  }
`;

const Switcher = styled(FlexWrapper)`
  margin: 20px 0 0 9px;
  padding-bottom: 35px;
  button {
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0; 
    margin-top: 0;
    padding-bottom: 25px; 
    align-items: end;
    justify-content: end;
  }
  }
`;

const FlexWrapperTerm = styled(FlexWrapper)`
  height: 70px;
  justify-content: space-between;
  margin-top: 24px;
  @media (max-width: 768px) {
    height: auto;
    margin-top: 20px;
    .mobile-column {
      flex-direction: column;
      width: 105%;
    }
  }
`;

const TabTerm = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  color: ${(props) => (props?.active ? '#000' : '#626262')};
  background: ${(props) =>
    props?.active
      ? `url(${radioactive}) no-repeat 4% 54%`
      : `url(${radio}) no-repeat 4% 54%`};
  transition: background-color 0.5s ease-in-out;
  padding: 21px 0px 20px 26px;
  margin-right: 10px;
  cursor: pointer;
  span {
    color: #2DB521;
    font-weight: 600;
    margin-left: 5px;
  }
  @media (max-width: 768px) {
    background: ${(props) =>
    props?.active
      ? `url(${radioactive}) no-repeat 4% 54%`
      : `url(${radio}) no-repeat 4% 54%`};
     padding: 2px 0px 0px 43px;
  }
`;

const Card = styled.div`
  background-position: 40% 49%;
  background-size: 110%;
  background-repeat: no-repeat;
  background-image: url(${img});
  border-radius: 8px;
  background: linear-gradient(288deg, #52B3F9 17.17%, #FFF 101.87%);
  border-radius: 8px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  width: 300px;
  height: 155px;
  @media (max-width: 768px) {
    width: 340px;
    margin: 0 auto;
  }
  @media (max-width: 380px) {
    width: 250px;
  }
`;

const Cardtitle = styled(TextLight)`
  padding: 10px 5px 5px 16px;
  color: #2C2C2C;
  font-size: 12px;
`;

const CardTitleDefault = styled.div`
  background: #e1f2fe 5% 49% / 24px  no-repeat url(${alert});
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding: 4px 10px 4px 46px;
  margin: 10px 0;
  color: ${(props) => props.theme.colors.lightGrey}
`;

const Cardnumber = styled(TextBlack)`
  font-weight: 400;
  background: #fff;
  width: fit-content;
  margin: 0 auto;
  padding: 5px 5px 5px 9px;
  width: 90%;
`;

const Carddate = styled.div`
  display: flex;
  width: 93%;
  justify-content: end;
  p {
    background: #fff;
    padding: 5px;
    margin-top: 25px;
    border-radius: 5px;
    font-weight: 400;
  }
`;

const CardButton = styled.div`
  margin: 8px 8px 0 0;
  text-align: start;
  .pointer {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    button {
      margin: 20px 0;
    }
    text-align: center;
  }
  @media (max-width: 480px) {
    margin-top: auto;
    margin-left: 15px;
  }
`;

const Plan = styled(Block)`
  margin: 10px 0 20px 0;
  padding: 15px 14px 5px 14px;
  width: 350px;
  flex-direction: column;
  border: ${(props) => (props.current ? '1px solid red' : null)};
  overflow: hidden;
  background: ${(props) => (props.popular ? `linear-gradient(103.15deg, #FFFFFF 10.41%, #EDFBFF 86.59%);` : '' )};
  transition: .3s;
  &:hover {
    box-shadow: 4px 12px 32px 0 rgba(0, 0, 0, 0.06);
    transform: scale(1.04);
    transition: .3s;
  }
  &.popular {
    border-radius: 13px;
    background: radial-gradient(97.32% 97.32% at 45.37% 2.68%, rgb(255, 255, 255) 0%, rgb(184, 255, 159) 100%);
  }
  .card-subtitle {
    font-size: 0.75rem;
    font-weight: 400;
  }
  .card {
    margin-left: auto;
    padding-left: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .card-price {
      font-size: 0.875rem;
      font-weight: 600;
      span {
        font-weight: 400;
      }
    }
  }
  &:last-child {
    margin: auto;
  }
  .current {
    margin-right: 22px;
  }
  @media (max-width: 768px) {
    margin: ${(props) => (props.popular ? '6px 0 6px 0' : '6px auto')};
    min-height: 250px;
  }
`;

const CardTop = styled(Flex)`
  justify-content: space-between;
`;

const Popular = styled.div`
  color: #117A3B;
  text-align: center;
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 600;
  padding: 5px 7px;
  border-radius: 10px;
  background: linear-gradient(94deg, #FFF -14.61%, #DCFFE4 106.47%);
  position: absolute;
  left: 135px;
  top: 6px;
`;

const Text = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 28px;
  margin: 10px 0;
  svg {
    margin-right: 10px;
    min-width: 26px
  }
`;

const CurrentBtn = styled.div`
  margin: auto auto;
  position: absolute;
  bottom: 30px;
  left: 34%;
  font-weight: 600;
`;

const Amount = styled.p`
  color: ${(props) => props.theme.colors.black};
  font-size: 46px;
  font-weight: 600;
  line-height: 0.45;
  text-align: center;
  margin: 37px 0 35px;
  &.current {
    opacity: 0;
  }
  span {
    font-size: 16px;
  }
`;

const Price = styled.p`
  color: ${(props) => props.theme.colors.black};
  font-size: 20px;
  font-weight: 600;
  line-height: .8;
  padding: 0 0 10px 20px;
  margin: 8px 0 0;
  span {
    font-size: 11px;
  }
  &.right {
    text-align: right;
  }
`;

const FlexCard = styled(Flex)`
  margin-left: auto;
  width: 100%;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
  ${(props) => (props?.active ? '' : 'display:none')};
  
  @media (max-width: 768px) {
    width: auto;
    margin-left: 0;
    margin-bottom: 15px;
    margin-right: 0;
    padding-right: 0;
    button {
      margin: 0 auto;
      height: 46px;
    }
  }
`;

const Select = styled(StyledSelect)`
  min-width: auto!important;
  padding-left: 55px;
  margin: 0!important;
`;

const Hidden = styled.div`
  padding: 20px 0;
  position: relative;
  text-align: center;
  &:after, &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 32px
    right:0;
    height: 1px;
    background: #C6D5DD;
    z-index: -1;
  }
  &:before {
    top: 36px;
  }
`;

const BtnHidden = styled.button`
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  border-radius: 30px;
  border: none;
  background:  #FFF;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.13);
  padding: 7px 80px;
`;

const CardBox = styled(Flex)`
  background: #fff;
  padding: 15px;
  margin-top: 20px;
  border-radius: 21px;
  align-items: center;
  justify-content: space-between;
  .subtitle {
    line-height: 1.5;
    width: 230px;
    margin-top: 0;
  }
  @media (max-width: 600px) { 
    flex-direction: column;
    .subtitle {
      width: auto;
      padding-left: 0;
    }
  }
`;

const FlexColumnCurrent = styled(FlexColumn)`
  height: 100%;
  position: relative;
`;

const CardBottom = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 0;
`;

const Button = styled.button`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
  width: 100%;
  margin: 20px 0;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 8px;
  background: #3B3E45;
  cursor: pointer;
  transition: 1s;
  &.disabled {
    background: #85929B;
    cursor: not-allowed;
    &:hover {
      background: #85929B;
    }
  }
  &:hover {
    border: none;
    outline: none;
    transition: .3s;  
    background: linear-gradient(91deg, #FFB673 0%, #C15011 105.09%);
  }
`;

const FlexBetween = styled(Flex)`
  justify-content: space-between;
`;

const CardUpd = styled.div`
@media (min-width: 600px) {
  padding-left: 40px;
} 
`;

const Partner = styled.div`
  padding: 30px;
  background: white;
  border-radius: 20px;
  margin-top: 60px;
  position: relative;
  display: flex;
  .img {
    min-width: 274px;
  }
  &:after {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    top: -60px;
    right: 0;
    height: 1px;
    width: 100%;
    background: #C6D5DD;
    z-index: -1;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .img {
      margin: 0 auto;
    }
  }
`;

const PartnerText = styled.div`
padding-left: 20px;
p {
font-size: 14px;
line-height: 161%;}
.top {
  margin-top: -4px;
  font-size: 17px
}
.link {
  margin-top: -4px;
}
@media (max-width: 768px) {
  margin-top: 25px
}
`; 

const More = styled.a`
  display: flex;
  text-decoration: none;
  p {
    margin: 0 0 0 5px;
  }
`;

const Btn = styled.a`
  font-family: inherit;
  padding: 10px 14px;
  font-size: .875rem;
  font-weight: 600;
  height: 37px;
  border-radius: 18px;
  border: none;
  color: #FEFDFD;
  cursor: pointer;
  background: #3B3E45;
  white-space: nowrap;
  text-decoration: none;
  margin-top: 26px;
  display: block;
  width: fit-content;
`;

export default function Index() {
  const { t: companyT } = useTranslation('company');
  
  const dispatch = useDispatch();

  const { details, subscription, payment, status, subscriptions } = useSelector(
    (state) => state.accountReducer
  );

  const [term, setTerm] = useState(1);
  const [active, setActive] = useState(1);
  const [modal, setModal] = useState();
  const [paymentForm, setPaymentForm] = useState();
  const [subscribeForm, setSubscribeForm] = useState();
  let [choosedMonth, setChoosedMonth] = useState();
  let [choosedAnnual, setChoosedAnnual] = useState();
  const [success, setSuccess] = useState();
  const [successDeactivate, setSuccessDeactivate] = useState();
  const [deactivate, setDeactivate] = useState();
  const [select, setSelect] = useState();
  const [annualListUsd] = useState();
  const [choosed, setChoosed] = useState(false);
  const [successSubscribe, setSuccessSubscribe] = useState(false);
  const [personalisationMonth, setPersonalisationMonth] = useState();
  const [personalisationAnnual, setPersonalisationAnnual] = useState();
  const [personalisations, setPersonalisations] = useState(false);
  const [personalisationAnnualAll, setPersonalisationAnnualAll] = useState();
  const [personalisationMonthAll, setPersonalisationMonthAll] = useState();
  const [discountSuccess, setDiscountSuccess] = useState(false);
  const [hidden, setHidden] = useState(true);

  // active:
  // 0 = USD
  // 1 = CAD
  // 2 = EUR
  // 3 = GBP

  // term:
  // 0 = monthly
  // 1 = annually

  const handleSelectChange = (event) =>  {
    setActive(event.value);
  };

  const curOptions = [
    {value: 0, label: 'USD'},
    {value: 1, label: 'CAD'},
    {value: 2, label: 'EUR'},
    {value: 3, label: 'GBP'}
  ];
  
  useEffect(() => {
    if (subscriptions) {
      if ((subscription.name === "Free lite plan" && details?.payment_card_currency != "CAD") || 
         (subscription?.name === 'VQ Unlimited' && details?.payment_card_currency != "CAD")) {
        if (details?.payment_card_currency) {
          setActive(details?.payment_card_currency == 'USD' ? 0 :
            details?.payment_card_currency == 'CAD' ? 1 :
              details?.payment_card_currency == 'EUR' ? 2 :
                details?.payment_card_currency == 'GBP' ? 3 : 1
          );
          setChoosed(subscriptions?.annual?.[details?.payment_card_currency][2]);
          setChoosedAnnual(annualListUsd?.find(e =>
            e.id === subscriptions?.annual?.[details?.payment_card_currency][2]?.id));
          setChoosedMonth();
        } else {
          setChoosed(subscriptions?.annual?.CAD[2]);
          setChoosedAnnual(annualListUsd?.find(e => e.id === subscriptions?.annual?.CAD[2]?.id));
        }
      } else if (subscription.name === "Free lite plan" || 
      details?.trial_end_date || (subscription?.name === 'VQ Unlimited' && details?.payment_card_currency == "CAD")) {
        if (details?.payment_card_currency) {
          setChoosed(subscriptions?.annual?.[details?.payment_card_currency][2]);
          setChoosedAnnual(annualListUsd?.find(e =>
            e.id === subscriptions?.annual?.[details?.payment_card_currency][2]?.id));
          setChoosedMonth();
        } else {
          setChoosed(subscriptions?.annual?.CAD[2]);
          setChoosedAnnual(annualListUsd?.find(e => e.id === subscriptions?.annual?.CAD[2]?.id));
        }
     
      } else {
        if (subscription?.name === 'VQ Unlimited'){
          setChoosed(false);
        } else if (details?.payment_card_currency) {
          setActive(details?.payment_card_currency == 'USD' ? 0 :
            details?.payment_card_currency == 'CAD' ? 1 :
              details?.payment_card_currency == 'EUR' ? 2 :
                details?.payment_card_currency == 'GBP' ? 3 : 1
          ); 
        } else {
          setChoosed(subscription);
        }
      }
    }
    setTerm(subscription?.frequency === 'annual' ? 1 : 0);
    /* eslint-disable */
  }, [dispatch, subscription, annualListUsd, subscriptions ]);
    /* eslint-enable */

  useEffect(() => {
    dispatch(fetchAllDetails({ details, payment, subscription }));
    setTerm(subscription?.frequency === 'annual' ? 1 : 0);
    /* eslint-disable */
  }, [dispatch, subscription?.frequency, subscription?.lead_limit ]);
    /* eslint-enable */

  useEffect(() => {
    dispatch(fetchAS({ subscriptions }));
    fetchAllDetails();
    /* eslint-disable */
  }, [dispatch]);
   /* eslint-enable */

  const getPersonalisationsAnnual = async () => {
    await network
      .get(`/api/personalisations`, {params: {currency: details?.payment_card_currency, frequency: 'annual'}})
      .then(({ data }) => {
        
        if(details?.payment_card_currency) {
          setPersonalisationAnnual(...data.results);
        }
        else {
          setPersonalisationAnnualAll(data.results);
          setPersonalisationAnnual();
        }
      })
      .catch((error) => console.log(error));
  };

  const getPersonalisationsMonthly = async () => {
    await network
      .get(`/api/personalisations`, {params: {currency: details?.payment_card_currency, frequency: 'monthly'}})
      .then(({ data }) => {
        if(details?.payment_card_currency) {
          setPersonalisationMonth(...data.results);
        }
        else {
          setPersonalisationMonthAll(data.results);
          setPersonalisationMonth();
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if(details.length != 0) {
      getPersonalisationsAnnual();
      getPersonalisationsMonthly();
    }
    /* eslint-disable */
  }, [details.length]);
  /* eslint-enable */
  
  useEffect(() => {
    if(success) {
      setModal(true);
    }
  }, [success]);

  const ctx = useContext(Responsive);
  
  function handleChooseNew(item) {
    setChoosed(item);
    setModal(true);
    setSubscribeForm(true);
  }

  useEffect(() => {
    if(personalisationMonthAll) {
      switch (active) {
      case 0:
        setPersonalisationMonth(personalisationMonthAll[0]);
        setPersonalisationAnnual(personalisationAnnualAll[0]);
        break;
      case 1:
        setPersonalisationMonth(personalisationMonthAll[1]);
        setPersonalisationAnnual(personalisationAnnualAll[1]);
        break;
      case 2:
        setPersonalisationMonth(personalisationMonthAll[2]);
        setPersonalisationAnnual(personalisationAnnualAll[2]);
        break;
      case 3:
        setPersonalisationMonth(personalisationMonthAll[3]);
        setPersonalisationAnnual(personalisationAnnualAll[3]);
        break;
      
      default:
        break;
      }
    }  
    /* eslint-disable */
  },[active, details]);
  /* eslint-enable */
  
  function renderPlan(item, idx) {
    return (
      <Plan key={item.id} className={(item.lead_limit == '500' && subscription?.id != item.id) ? 'popular' : ''}>
        <>
          <CardTop>
            <Price>
              {personalisations[idx] ? 
                (
                  term === 1
                    ? (active === 0 && '$' + 
                      (Math.floor(item?.stripe_payment_amount/12 + 
                      +personalisationAnnual?.stripe_payment_amount/12)) ) ||
                    (active === 1 && '$' + 
                      (Math.floor(item?.stripe_payment_amount/12+ 
                      +personalisationAnnual?.stripe_payment_amount/12)) ) ||
                    (active === 2 && '€' + 
                      (Math.floor((+item?.stripe_payment_amount + 
                        +personalisationAnnual?.stripe_payment_amount)/12)) ) ||
                    (active === 3 && '£' + 
                      (Math.floor(item?.stripe_payment_amount/12 + +personalisationAnnual?.stripe_payment_amount/12)) )
                    : 
                    (active === 0 && '$' + 
                      (Math.floor(item?.stripe_payment_amount)+ +personalisationMonth?.stripe_payment_amount)) ||
                    (active === 1 && '$' + 
                      (Math.floor(item?.stripe_payment_amount)+ +personalisationMonth?.stripe_payment_amount)) ||
                    (active === 2 && '€' + 
                      (Math.floor(item?.stripe_payment_amount)+ +personalisationMonth?.stripe_payment_amount)) ||
                    (active === 3 && '£' + 
                    (Math.floor(item?.stripe_payment_amount)+ +personalisationMonth?.stripe_payment_amount))
                )
                :
                (term === 1
                  ? (active === 0 && '$' + Math.floor(item?.stripe_payment_amount/12) ) ||
                  (active === 1 && '$' + Math.floor(item?.stripe_payment_amount/12) ) ||
                  (active === 2 && '€' + Math.floor(item?.stripe_payment_amount/12) ) ||
                  (active === 3 && '£' + Math.floor(item?.stripe_payment_amount/12) )
                  : (active === 0 && '$' + Math.floor(item?.stripe_payment_amount)) ||
                  (active === 1 && '$' + Math.floor(item?.stripe_payment_amount)) ||
                  (active === 2 && '€' + Math.floor(item?.stripe_payment_amount)) ||
                  (active === 3 && '£' + Math.floor(item?.stripe_payment_amount))
                )
              }/
              <span>{term === 1 ? companyT('annual') : companyT('month')}</span>
            </Price>
            {(item.lead_limit == '500' && subscription?.id != item.id) && (
              <Popular>{companyT('popular')}</Popular>
            )}
          </CardTop>
          <Amount className={subscription?.id === item.id ? 'current' : ''}>
            {item.lead_limit + ' '}<br/><span>{companyT('companies')}</span>
          </Amount>
          { subscription?.id === item.id ? 
            (<FlexColumnCurrent>
              <Text>
                <Current />
                {item.lead_limit + ' '}{companyT('companies')}
              </Text>
              {details?.settings.allow_personalisation == '1' && (
                <Text>
                  <PersonalizationIcon />
                  {companyT('included')}
                </Text>
              )}
              <CurrentBtn>
                {companyT('current')} 
              </CurrentBtn>

            </FlexColumnCurrent>)
            : <>
              <PaidPersonalization 
                personalisations={personalisations} 
                setPersonalisations={setPersonalisations}
                idx={idx}
                cur={details?.payment_card_currency || active === 0 && 'USD'
                || active === 1 && 'CAD'
                || active === 2 && 'EUR'
                || active === 3 && 'GBP'} 
                add={term === 0 ? (personalisationMonth?.stripe_payment_amount)
                  : Math.floor(+personalisationAnnual?.stripe_payment_amount/12)
                }
              />
              <Button 
                // className={term === 0 && item.lead_limit == '100' ? 'disabled': ''}
                // onClick={term === 0 && item.lead_limit == '100' ? null : () => handleChooseNew(item)}>
                onClick={() => handleChooseNew(item)}>
                { companyT('choose')}
              </Button>
            </>}
        </>
      </Plan>
    );
  }
  
  function handleTabTerm(e) {
    setTerm(() => +e.target.id);
    setActive(active);
  }

  function handleHidden() {
    setHidden(false);
  }

  function handleSubscribeOff() {
    setModal(false);
    setPaymentForm(false);
    setSubscribeForm(false);
    setSuccess(false);
    setDeactivate(false);
    setSuccessSubscribe(false);
    setSelect(false);
    setSuccessDeactivate(false);
    setDiscount(false);
  }

  function handlePaymentForm() {
    setModal(true);
    setPaymentForm(true);
  }

  function handleDeactivate() {
    setModal(true);
    setDeactivate(true);
  }

  const styles = {
    option: (provided, state) => ({
      ...provided,
      color: '#000000',
      fontSize: '12px',
      background: 'inherit',
      borderBottom: state.isSelected ? '1px solid #EDEDED' : '0',
      '&:hover': {
        background: `#FFF9F9`,
      },
      padding: state.isSelected ? '10px 5px 10px 15px' : '10px 5px 10px 15px',
      width: ctx.isMobile ? 200 : 80,
    }),
    control: () => ({
      display: 'flex',
      borderRadius: '8px',
      //width: ctx.isMobile ? 180 : 300,
      border: 'none',
      background: `#fff `,
      boxShadow: '0px 1px 5px rgba(87, 85, 85, 0.24)',
      marginTop: '4px',
      '@media only screen and (min-width: 1300px)': {
        width: 90,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      width: ctx.isMobile ? 240 : 90,
      color: state.selectProps.menuColor,
      padding: 0,
      marginTop: '25px',
      overflowX: 'hidden',
      display: 'inline-block',
      zIndex: 9,
      '@media only screen and (min-width: 1200px)': {
        width: 80,
      },
    }),

  };

  useEffect(() => {
    if (successDeactivate) {
      const timer = setTimeout(() => {
        setSuccessDeactivate(false);
        setModal(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [successDeactivate]);

  const [radio, setRadio] = useState('');
  const [text, setText] = useState('');
  const [discount, setDiscount] = useState(false);
  const hidenCard = false;

  function handleDeactivateAcc() {
    setDiscount(true);
    setSelect(false);
  }

  function PostDeactivate() {
    handleSubscribeOff();
    network
      .post('/api/account/deactivate', {
        reason: radio,
        other: text
      })
      .then(({ data }) => {
        // setSuccessDeactivate(true);
        setSelect(false);
        // setModal(true);
        setDiscount(false);
        window.location.reload();
        return data;
      })
      .catch((error) => console.log(error));
  }

  function handleDiscount() {
    network
      .post('/api/account/retention_discount')
      .then(({ data }) => {
        setSuccessDeactivate(true);
        setSelect(false);
        setModal(true);
        setDiscountSuccess(true);
        setDiscount(false);
        return data;
      })
      .catch((error) => console.log(error));
  }

  function Affiliate() {
    return (
      <Partner>
        <Image className='img'/>
        <PartnerText>
          <TextBlack className='top'>{companyT('become')}</TextBlack>
          <TextBlackThin>{companyT('become_text')}</TextBlackThin>
          <More href='https://www.visitorqueue.com/affiliate' target="_blank">
            <Link />
            <TextBlackSmall className='link'>{companyT('more_link')}</TextBlackSmall>
          </More>
          <Btn href='https://partner.visitorqueue.com/'>{companyT('btn')}</Btn>
        </PartnerText>
      </Partner>
    );
  }

  return (
    <>
      {status === 'loading' ? (
        <Loader />
      ) : (
        <Company>
          <>
            <TitleBlack>{companyT('title')}</TitleBlack>
            <TextBlackBig>{companyT('subtitle')}</TextBlackBig>
          </>
          {successSubscribe && (
            <Modal>
              <Overflow onClick={handleSubscribeOff}></Overflow>
              <SuccessSubscribe
                close={handleSubscribeOff}
                subscription={success}
                details={details}
              />
            </Modal>
          )}
          <Flex>
            {/* terms */}
            <FlexColumn className={hidden ? 'hidden' : ''}>   
              <FlexWrapperTerm>
                <Flex className='mobile-column'>
                  <TabTerm onClick={handleTabTerm} active={term === 0} id={0}>
                    {companyT('monthly')}
                  </TabTerm>
                  <TabTerm onClick={handleTabTerm} active={term === 1} id={1}>
                    {companyT('annually')}
                    <span> {companyT('save20')}</span>
                  </TabTerm>
                </Flex>
                {!details?.payment_card_currency && (
                  <Switcher>
                    <Select 
                      defaultValue={{ label: "CAD", value: 1 }}
                      options={curOptions}
                      onChange={handleSelectChange}
                      styles={styles}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    />
                  </Switcher>
                )}
              </FlexWrapperTerm>         
              {/* end terms */}
              <FlexCard active={term === 0}>
                {active === 0 && subscriptions?.monthly?.USD?.map(renderPlan) ||
                active === 1 && subscriptions?.monthly?.CAD?.map(renderPlan) ||
                active === 2 && subscriptions?.monthly?.EUR?.map(renderPlan) ||
                active === 3 && subscriptions?.monthly?.GBP?.map(renderPlan) }           
                {modal && subscribeForm && (
                  <Modal>
                    <Overflow onClick={handleSubscribeOff}></Overflow>
                    {!success && (
                      <SubscribeForm
                        handleSubscribeOff={handleSubscribeOff}
                        choosedMonth={choosedMonth}
                        setSuccess={setSuccess}
                        choosedAnnual={choosedAnnual}
                        subscription={subscription}
                        details={details}
                        active={active}
                        choosed={choosed}
                        cur={choosed?.currency}
                        payment={payment}
                        term={term}
                        personalisation={
                          term === 0
                            ? personalisationMonth
                            : personalisationAnnual
                        }
                        personalisations={personalisations}
                        setPersonalisations={setPersonalisations}
                        add={
                          term === 0
                            ? personalisationMonth?.stripe_payment_amount
                            : Math.floor(
                              +personalisationAnnual?.stripe_payment_amount / 12
                            )
                        }
                      />
                    )}
                    {success && (
                      <SuccessSubscribe
                        close={handleSubscribeOff}
                        subscribtionNew={success}
                        subscription={subscription}
                        choosed={choosed}
                      />
                    )}
                  </Modal>
                )}
              </FlexCard>
              <FlexCard active={term === 1}>
                {active === 0 && subscriptions?.annual?.USD?.map(renderPlan) ||
                active === 1 && subscriptions?.annual?.CAD?.map(renderPlan) ||
                active === 2 && subscriptions?.annual?.EUR?.map(renderPlan) ||
                active === 3 && subscriptions?.annual?.GBP?.map(renderPlan) }
               
                {modal && subscribeForm && (
                  <Modal>
                    <Overflow onClick={handleSubscribeOff}></Overflow>
                    {!success && (
                      <SubscribeForm
                        handleSubscribeOff={handleSubscribeOff}
                        choosedMonth={choosedMonth}
                        setSuccess={setSuccess}
                        choosedAnnual={choosedAnnual}
                        subscription={subscription}
                        details={details}
                        active={active}
                        choosed={choosed}
                        cur={choosed?.currency}
                        term={term}
                        personalisation={
                          term === 0
                            ? personalisationMonth
                            : personalisationAnnual
                        }
                        personalisations={personalisations}
                        setPersonalisations={setPersonalisations}
                        add={
                          term === 0
                            ? personalisationMonth?.stripe_payment_amount
                            : Math.floor(
                              +personalisationAnnual?.stripe_payment_amount / 12
                            )
                        }
                      />
                    )}
                    {success && (
                      <SuccessSubscribe
                        close={handleSubscribeOff}
                        subscribtionNew={success}
                        subscription={subscription}
                        choosed={choosed}
                      />
                    )}
                  </Modal>
                )}
              </FlexCard>
            </FlexColumn>
          </Flex>
          {hidden && (
            <Hidden>
              <BtnHidden onClick={handleHidden}>{companyT('showMore')}</BtnHidden>
            </Hidden>
          )}
          {hidenCard && <CardBox>
            <CardUpd>
              <TextBlack className='subtitle'>{companyT('updateCurrent')}</TextBlack>
              <CardButton>
                {details?.payment_card_currency != null && (
                  <SecondaryBtnHigh className='pointer' onClick={handlePaymentForm}>
                    {companyT('update')}
                  </SecondaryBtnHigh>
                )}
                {modal && paymentForm && (
                  <Modal>
                    <Overflow
                      onClick={handleSubscribeOff}
                    ></Overflow>
                    {!success && (
                      <Payment
                        handleSubscribeOff={handleSubscribeOff}
                        setSuccess={setSuccess}
                        details={details}
                        term={term}
                      />
                    )}
                    {success && <Success />}
                  </Modal>
                )}
              </CardButton>
            </CardUpd>
              
            <Card>
              {payment.length !== 0 ? (
                <>
                  <FlexBetween>
                    <Cardtitle>{companyT('activeCard')}</Cardtitle>
                  </FlexBetween>
                  <Cardnumber>
                    xxxx-xxxx-xxxx-{payment[0]?.last4}
                  </Cardnumber>
                </>
              ) : (
                <>
                  <CardTitleDefault>
                    {companyT('noActiveCard')}
                  </CardTitleDefault>
                  <Cardnumber>xxxx-xxxx-xxxx-xxxx</Cardnumber>
                </>
              )}
              <CardBottom>
                <VisaIcon />
                <Carddate>
                  <TextBlackSmallThin>
                    {payment.length !== 0
                      ? payment[0]?.exp_month + '/' + payment[0]?.exp_year
                      : '--/--'}
                  </TextBlackSmallThin>
                </Carddate>
              </CardBottom>
            </Card>
          </CardBox>}
          <Affiliate />
          <TextAccentSmall
            onClick={handleDeactivate}
            className="button deactivate"
          >
            {companyT('deactivateAccount')}
          </TextAccentSmall>
          {modal && deactivate && (
            <Modal>
              <Overflow onClick={handleSubscribeOff}></Overflow>
              {!success && (
                <Deactivate
                  handleSubscribeOff={handleSubscribeOff}
                  setSelect={setSelect}
                  setDeactivate={setDeactivate}
                />
              )}
            </Modal>
          )}
          {modal && select && (
            <Modal>
              <Overflow onClick={handleSubscribeOff}></Overflow>
              {!success && (
                <SelectDeactivate
                  handleSubscribeOff={handleSubscribeOff}
                  setSelect={setSelect}
                  setSuccessDeactivate={setSuccessDeactivate}
                  setModal={setModal}
                  setRadio={setRadio} setText={setText}
                  handleDeactivateAcc={handleDeactivateAcc}
                  radio={radio} 
                  text={text}
                />
              )}
            </Modal>
          )}
          {modal && discount && (
            <Modal>
              <Overflow onClick={handleSubscribeOff}></Overflow>
              {!success && (
                <Discount
                  PostDeactivate={PostDeactivate}
                  handleDiscount={handleDiscount}
                  coupon={subscription?.stripe_payment_amount == "0.0" || subscription?.stripe_payment_amount == "0"}
                />
              )}
            </Modal>
          )}
          {modal && successDeactivate && (
            <Modal>
              <Overflow onClick={handleSubscribeOff}></Overflow>
              <Success text={discountSuccess ? companyT('discountSuccess') : companyT('deactivated')} />
            </Modal>
          )}
        </Company>
      )}
    </>
  );
}

Index.propTypes = {
  payment: node,
  details: node,
  subscription: node
};
