import { useState } from 'react';
import styled from 'styled-components';
import { any, number, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ManageBox,
  TextAccentExtraSmall,
  TextBlackExtraSmall,
  TextBlackThin,
  TextLightExtraSmall,
  Modal,
  Overflow,
  Block,
} from 'base/styled';
import Batch from './Batch';
import { ReactComponent as Mail } from 'assets/images/mail.svg';
import { ReactComponent as Cell } from 'assets/images/cell.svg';
import { ReactComponent as Twitter } from 'assets/images/twitter_logo.svg';
import { ReactComponent as Linkedin } from 'assets/images/linkedin_logo.svg';
import { ReactComponent as In } from 'assets/images/in.svg';
import { ReactComponent as Send } from 'assets/images/send.svg';
import { ReactComponent as ContactIcon } from 'assets/images/export.svg';
import { ReactComponent as Hubspot } from 'assets/images/hubspot.svg';
import Search from 'base/components/Leads/Search';
import { useEffect } from 'react';
import SendContact from './Forms/SendContact';

const Wrap = styled.div`
  padding: 30px 0;
  background: ${(props) => props.theme.colors.white};
`;

const Top = styled.div`
  padding: 0 20px 10px;
  display: flex;
  justify-content: space-between;
  form {
    width: 65%;
    input {
      padding-left: 30px;
    }
    svg {
      left: 13px;
    }
  }
`;

const FilterWrap = styled.div`
  display: flex;
  p {
    color: #626262;
  }
`;

const Contact = styled.div`
  display: flex;
  background: white;
  border: ${(props) => props.theme.borders.textInput};
  border-radius: 8px;
  padding: 5px 5px 10px 15px;
`;

const Flex = styled.div`
  display: flex;
  svg {
    width: 18px;
    margin-right: 8px;
  }
  p {
    margin-top: 0;
  }
  .phone {
    padding-top: 3px;
  }
`;

const ContactLeft = styled.div`
  width: 40%;
  padding-right: 15px;
`;

const ContactCenter = styled.div`
  width: 55%;
`;

const ContactRight = styled.div`
  padding-top: 5px;
  position: relative;
`;

const Social = styled.div`
  display: flex;
  svg {
    margin-right: 15px;
  }
`;

const TextBlack = styled(TextBlackExtraSmall)`
  font-weight: 500;
`;

const Manage = styled(ManageBox)`
  right: 25px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0px;
  p {
    padding: 7px 10px 9px 5px;
    min-width: 140px;
    cursor: pointer;
  }
`;

const Popup = styled(Block)`
  margin: -2% auto 0;
  padding: 20px 0 10px;
`;

const PopupBtn = styled(Block)`
  margin: -2% auto 0;
  padding: 20px 0 0px;
  width: 190px;
`;

const Button = styled.div`
  font-weight: 400;
  font-size: 12px;
  text-decoration: none;
  display: flex;
  color: #1d1d1d;
  padding: 7px 15px;
  box-shadow: 0px 1px 5px rgba(87, 85, 85, 0.24);
  border-radius: 19px;
  margin: 0 auto;
  cursor: pointer;
  svg {
    margin-right: 15px;
  }
`;

const ModalBtn = styled.div`
  position: absolute;
  top: 105px;
  left: 40%;
  z-index: 25;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  a {
    font-size: 0.75rem;
    color: inherit;
    text-decoration: none;
    margin-bottom: 15px;
  }
`;

export default function Contacts({
  contactsInfo,
  companyInfo,
  integrations,
  id,
}) {
  const { t: homeT } = useTranslation('home');
  const [name, setName] = useState();
  const [show, setShow] = useState(false);
  const [modals, setModal] = useState(false);
  const [contact, setContact] = useState(false);
  const [lead, setLead] = useState(false);
  const [hubspot, setHubspot] = useState(false);
  const [open, setOpen] = useState(false);

  const handleShow = (idx) => () => {
    setShow((state) => ({
      ...state,
      [idx]: !state[idx],
    }));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleContact = (idx) => () => {
    setModal((state) => ({
      ...state,
      [idx]: !state[idx],
    }));
    setContact(true);
  };

  const handleLead = (idx) => () => {
    setModal((state) => ({
      ...state,
      [idx]: !state[idx],
    }));
    setLead(true);
  };

  const handleHubspot = (idx) => () => {
    setModal((state) => ({
      ...state,
      [idx]: !state[idx],
    }));
    setHubspot(true);
  };

  function handleModalOff() {
    setModal(false);
    setShow(false);
    setContact(false);
    setLead(false);
    setHubspot(false);
  }

  const showHubspot = false;

  function renderContacts(item, idx) {
    return (
      <Contact key={idx}>
        <ContactLeft>
          <TextBlack>
            {item.full_contact_person_info?.fullName
              ? item.full_contact_person_info.fullName
              : item.first_name
                ? item.first_name + ' ' + item.last_name
                : homeT('noName')}
          </TextBlack>
          <Social>
            {item.twitter && (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://twitter.com/` + item.twitter}
              >
                <Twitter />
              </a>
            )}
            {item.linkedin && (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.linkedin.com/` + item.linkedin}
              >
                <Linkedin />
              </a>
            )}
          </Social>
        </ContactLeft>
        <ContactCenter>
          {item.position ? (
            <TextLightExtraSmall>{item.position}</TextLightExtraSmall>
          ) : (
            <TextLightExtraSmall>{homeT('noPosition')}</TextLightExtraSmall>
          )}
          <div>
            <Flex>
              <Mail />
              {item.value ? (
                <TextAccentExtraSmall>{item.value}</TextAccentExtraSmall>
              ) : (
                <TextLightExtraSmall>{homeT('noMail')}</TextLightExtraSmall>
              )}
            </Flex>
            <Flex>
              <Cell />
              {item.phone_number ? (
                <TextAccentExtraSmall>{item.phone_number}</TextAccentExtraSmall>
              ) : (
                <TextLightExtraSmall className="phone">
                  {homeT('noPhone')}
                </TextLightExtraSmall>
              )}
            </Flex>
          </div>
        </ContactCenter>

        {integrations
          ? (integrations?.find((e) => e.title.includes('SugarCRM'))
            ?.connected ||
              integrations?.find((e) => e.title.includes('Hubspot'))
                ?.connected) && (
            <ContactRight onClick={handleShow(idx)}>
              {showHubspot && <Send />}
              {show[idx] && (
                <Manage>
                  <Box>
                    {integrations?.find((e) => e.title.includes('SugarCRM'))
                      ?.connected && (
                      <>
                        <Flex>
                          <TextBlackThin onClick={handleContact(idx)}>
                            {homeT('sendContact')}
                          </TextBlackThin>
                          <ContactIcon />
                        </Flex>
                        <Flex>
                          <TextBlackThin onClick={handleLead(idx)}>
                            {homeT('sendLead')}
                          </TextBlackThin>                            
                          <ContactIcon />
                        </Flex>
                      </>
                    )}
                    {integrations?.find((e) => e.title.includes('Hubspot'))
                      ?.connected && (
                      <Flex>
                        <TextBlackThin onClick={handleHubspot(idx)}>
                          {homeT('sendHubspot')}
                        </TextBlackThin>
                        <Hubspot />
                      </Flex>
                    )}
                  </Box>
                </Manage>
              )}
            </ContactRight>
          )
          : null}
        {modals[idx] && (
          <>
            <Modal>
              <Overflow onClick={handleModalOff}></Overflow>
              <Popup>
                <SendContact
                  handleOpenAddOff={handleModalOff}
                  name={
                    item.full_contact_person_info?.fullName
                      ? item.full_contact_person_info.fullName
                      : item.first_name
                        ? item.first_name + ' ' + item.last_name
                        : homeT('noName')
                  }
                  mail={item.value}
                  number={item.phone_number}
                  job={item.position}
                  contact={contact}
                  lead={lead}
                  id={id}
                  hubspot={hubspot}
                />
              </Popup>
            </Modal>
          </>
        )}
      </Contact>
    );
  }

  useEffect(() => {
    var re = / /g;
    setName(companyInfo?.company_name?.toLowerCase().replace(re, '-'));
    console.log(name);
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  return (
    <Wrap>
      <Top>
        {contactsInfo.results && contactsInfo.results.length > 0 ? (
          <>
            <Search />
            <FilterWrap>
              <TextLightExtraSmall>Filters</TextLightExtraSmall>
              <Batch />
            </FilterWrap>
          </>
        ) : (
          <Button onClick={handleOpen}>
            <In />
            {homeT('findContacts')}
          </Button>
        )}
      </Top>
      {contactsInfo.results && contactsInfo.results.map(renderContacts)}
      {open && (
        <>
          <Modal onClick={() => setOpen(false)}></Modal>
          <ModalBtn>
            <PopupBtn>
              <Links>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`https://www.linkedin.com/search/results/people/?company=${companyInfo?.company_name
                    ?.toLowerCase()
                    .replace(/ /g, '-')}`}
                >
                  {homeT('findPeople')}
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`https://www.linkedin.com/sales/search/people?companyIncluded=${companyInfo?.company_name
                    ?.toLowerCase()
                    .replace(/ /g, '-')}&companyTimeS`}
                >
                  {homeT('findSales')}
                </a>
              </Links>
            </PopupBtn>
          </ModalBtn>
        </>
      )}
    </Wrap>
  );
}

Contacts.propTypes = {
  contactsInfo: any,
  companyInfo: object,
  integrations: any,
  id: number,
};
