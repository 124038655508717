import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Block, Flex, Loading } from "base/styled";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import TooltipDark from "base/components/TooltipDark";
import Checked from "assets/images/radio_active.svg";
import Check from "assets/images/radio_circle.svg";
import { ReactComponent as ChartIcon } from "assets/images/chart.svg";
import { bool, number } from "prop-types";
import NoStatystic from "./NoStatystic";
import network from "base/network";

const ChartBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-left: 25px;
  width: ${(props) => (props.details ? "74%" : "56%;")};
  @media (max-width: 1485px) {
    width: ${(props) => (props.details ? "74%" : "55%;")};
  }
  @media (max-width: 1345px) {
    width: ${(props) => (props.details ? "71%" : "53%;")};
  }
  @media (max-width: 1230px) {
    width: ${(props) => (props.details ? "69%" : "51%;")};
  }
  @media (max-width: 1160px) {
    width: ${(props) => (props.details ? "65%" : "48%;")};
  }
  .line-tooltip {
    top: -2px;
    left: -16px;
   }
  @media (max-width: 768px) {
   padding: 8px 2px 6px;
   width: 100%;
   .line-tooltip {
    top: -3px;
    left: 8px;
   }
  }
`;

const Text = styled.p`
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0rem;
  color: ${(props) => props.theme.colors.lightText};
  margin: 0 10px;
  @media (max-width: 768px) {
    padding-left: 20px;
  }
`;

const Tab = styled.div`
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0rem;
  margin: 0 10px;
  padding-left: 20px;
  cursor: pointer;
  &.check {
    background: url(${Check}) no-repeat 4% center;
    color: ${(props) => props.theme.colors.lightText};
  }
  &.active {
    background: url(${Checked}) no-repeat 4% center;
    color: ${(props) => props.theme.colors.accentRed};
  }
`;

const FlexWrap = styled(Flex)`
  justify-content: space-between;
  .chartjs-render-monitor {
    color: red
  }
`;

const Round = styled(Flex)`
  justify-content: space-around;
  svg {
    margin-top: 20px;
  }
`;

const Loader = styled(Loading)`
  width: 100%;
  height: 178px;
  margin-top: 0;
`;

function LineChart({ details, id }) {
  const { t: homeT } = useTranslation("home");
  const [active, setActive] = useState(0);
  const [results, setResult] = useState();
  const [data, setData] = useState([]);
  const [empty, setEmpty] = useState(false);

  const options = {
    legend: {
      display: false,
    },
    type: "line",
    lineThickness: 1,
    borderWidth: 1,
    animation: {
      duration: 0,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            font: {
              family: 'Comic Sans MS'
            },
          },
        },
      ],
      xAxes: {
        barPercentage: 1.6,
        grid: {
          display: false,
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          
          font: {
            family: "Montserrat", // Add your font here to change the font of your x axis
            size: 12
          },
  
          major: {
            enable: false
          }
        }
      }
    },
    plugins: {
      tooltip: {
        titleFont: {
          family: 'commic-sans-ms'
        }
      }
    }
  };

  function handleTab(e) {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  }

  async function repeatRequest() {
    try {
      const response = await network.get("api/dashboard_stats/unique_chart", {
        params: { website_id: id },
      });
      if (response.status === 200) {
        setResult(response.data.results);
        return response;
      } else {
        throw new Error("Response status not 200");
      }
    } catch (error) {
      let sec = 500;
      sec += 2000;
      setTimeout(repeatRequest, sec);
    }
  }
 
  useEffect(() => {
    repeatRequest();
    /* eslint-disable */
  }, [id]);
  /* eslint-enable */

  useEffect(() => {
    if (results) {
      setData({
        labels:
          (active === 0 &&
            Object.keys(results[0].today).map((item) => item.slice(11, 16))) ||
          (active === 1 &&
            Object.keys(results[1].week).map((item) => item.slice(0, 10))) ||
          (active === 2 &&
            Object.keys(results[2].month).map((item) => item.slice(5, 10))),
        datasets: [
          {
            label: 'amount of new leads',
            data:
              (active === 0 && Object.values(results[0].today)) ||
              (active === 1 && Object.values(results[1].week)) ||
              (active === 2 && Object.values(results[2].month)),
            fill: false,
            backgroundColor: "#6AB4F9",
            borderColor: "#6AB4F9",
            borderWidth: 1,
            pointRadius: 2,
            fontSize: 20
          },
        ],
      });
      setEmpty(
        Object.values(results[0].today)?.length == 0 &&
          Object.values(results[1].week)?.length == 0 &&
          Object.values(results[2].month)?.length == 0
      );
    }
  }, [results, active]);

  return (
    <ChartBlock details={details}>
      {results ? (
        <>
          <TooltipDark text={homeT('tooltipChart')} className="line-tooltip"/>
          {empty ? (
            <Round>
              <NoStatystic />
              <ChartIcon />
            </Round>
          ) : (
            <>
              <FlexWrap>
                <Text>{new Date().toLocaleDateString()}</Text>
                <Flex>
                  <Tab
                    onClick={handleTab}
                    active={active === 0}
                    id={0}
                    className={active === 0 ? 'active' : 'check'}
                  >
                    {homeT('today')}
                  </Tab>
                  <Tab
                    onClick={handleTab}
                    active={active === 1}
                    id={1}
                    className={active === 1 ? 'active' : 'check'}
                  >
                    {homeT('week')}
                  </Tab>
                  <Tab
                    onClick={handleTab}
                    active={active === 2}
                    id={2}
                    className={active === 2 ? 'active' : 'check'}
                  >
                    {homeT('month')}
                  </Tab>
                </Flex>
              </FlexWrap>
              <div>
                <Line data={{...data}} options={options} width={450} height={80} />
              </div>
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </ChartBlock>
  );
}

export default LineChart;

LineChart.propTypes = {
  details: bool,
  id: number,
};
