import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { any, number, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Responsive from 'context/responsive';
import {
  ManageBox,
  TextAccentExtraSmall,
  TextBlackExtraSmall,
  TextBlackThin,
  TextLightExtraSmall,
  Modal,
  Overflow,
  Block,
  FilledLink
} from 'base/styled';
import { ReactComponent as Mail } from 'assets/images/mail.svg';
import { ReactComponent as Cell } from 'assets/images/cell.svg';
import { ReactComponent as Twitter } from 'assets/images/twitter_logo.svg';
import { ReactComponent as Linkedin } from 'assets/images/linkedin_logo.svg';
import { ReactComponent as In } from 'assets/images/in.svg';
import { ReactComponent as Send } from 'assets/images/send.svg';
import { ReactComponent as ContactIcon } from 'assets/images/export.svg';
import { ReactComponent as Hubspot } from 'assets/images/hubspot.svg';
import { ReactComponent as Filters } from 'assets/images/filters.svg';
import SendContact from './Forms/SendContact';
import Search from '../../Search';
import network from 'base/network';
import ContactFilter from './ContactFilter';
import Suggest from '../../Manage/Suggest';

const Wrap = styled.div`
  background: transparent;
  &.transparent {
    background: transparent;
  }
  form {
    width: 65%;
    margin: 0 auto;
    margin-bottom: 15px;
    &.full {
      width: 100%;
    }
  }
`;

const Top = styled.div`
  padding: 20px 20px 35px;
  display: flex;
  justify-content: space-between;
  &.open {
    height: 130px;
  }
  &.column {
    flex-direction: column;
    p {
      text-align: center;
      line-height: 20px;
      span {
        color: ${props => props.theme.colors.accentRed};
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
  form {
    width: 65%;
    input {
      padding-left: 30px;
    }
    svg {
      left: 13px;
    }
  }
`;

const TopWithContacts = styled.div`
  padding: 10px 5px;
  display: flex;
  form {
    width: 50%;
  }
`;

const Filter = styled.div`
  cursor: pointer;
  background: #FFECDB;
  border-radius: 27px;
  padding: 8px;
  height: 36px;
  width: 36%;
  display: flex;
  align-items: center;
  position: relative;
  p {
    margin-left: 10px;
  } 

`;

const Contact = styled.div`
  display: flex;
  background: white;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 12px 0px #00000021;
  margin: 3px 5px;
  padding: 5px 5px 10px 15px;
`;

const Flex = styled.div`
  display: flex;
  svg {
      width: 18px;
      margin-right: 8px;
  }
  p {
    margin-top: 0;  
  }
  .phone {
    padding-top: 3px;
  }
`;

const ContactLeft = styled.div`
  width: 40%;
  padding-right: 15px;
`;

const ContactCenter = styled.div`
  width: 55%;
`;

const ContactRight = styled.div`
  padding-top: 5px;
  position: relative;
`;

const Social = styled.div`
  display: flex;
  svg {
      margin-right: 15px;
  }
`;

const TextBlack = styled(TextBlackExtraSmall)`
  font-weight: 500;
`;

const Manage = styled(ManageBox)`
  right: 25px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0px;
  p {
    padding: 7px 10px 9px 5px;
    min-width: 150px;
    cursor: pointer;
  }
`;

const Popup = styled(Block)`
  margin: 2% auto 0;
  padding: 20px 0 10px;
  height: 545px
`;

const PopupBtn = styled(Block)`
  margin: -2% auto 0;
  padding: 7px 0 0px;
  width: 190px;
  box-shadow: 0px 1px 5px rgba(87,85,85,0.24);
`;

const Button = styled.div`
  font-weight: 400;
  font-size: 12px;
  text-decoration: none;
  display: flex;
  color: #1D1D1D;
  padding: 7px 15px;
  box-shadow: 0px 1px 5px rgba(87, 85, 85, 0.24);
  border-radius: 19px;
  margin: 0 auto;
  cursor: pointer;
  svg {
    margin-right: 15px
  }
`;

const ModalBtn = styled.div`
  position: absolute;
  top: 89px;
  left: 100px;
  z-index: 25;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  a {
    font-size: .75rem;
    color: inherit;
    text-decoration: none;
    margin-bottom: 15px;
  }
`;

const Wrapper = styled(Block)`
  margin: 30px;
  justify-content: center;
  flex-direction: column;
  font-size: 10px;
  text-align: center;
  a {
    font-size: 14px;
    width: 140px;
    margin: 16px auto 0;
  }
`;

export default function Contacts({companyInfo, integrations, id, enabled}){
  const { t: homeT } = useTranslation('home');
  const [show, setShow] = useState(false);
  const [modals, setModal] = useState(false);
  const [contact, setContact] = useState(false);
  const [lead, setLead] = useState(false);
  const [hubspot, setHubspot] = useState(false);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [contactInfo, setContactInfo] = useState([]);
  const [search, setSearch] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [success, setSuccess] = useState(false);
  const ctx = useContext(Responsive);

  const handleShow = (idx) => () => {
    setShow((state) => ({
      ...state,
      [idx]: !state[idx],
    }));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleContact = (idx) => () => {
    setModal((state) => ({
      ...state,
      [idx]: !state[idx],
    }));
    setContact(true);
  };

  const handleLead = (idx) => () => {
    setModal((state) => ({
      ...state,
      [idx]: !state[idx],
    }));
    setLead(true);
  };

  const handleHubspot = (idx) => () => {
    setModal((state) => ({
      ...state,
      [idx]: !state[idx],
    }));
    setHubspot(true);
  };

  function handleModalOff(){
    setModal(false);
    setShow(false);
    setContact(false);
    setLead(false);
    setHubspot(false);
  }

  const showHubspot = false;

  function renderContacts(item, idx) {
    return (
      <Contact key={idx}>
        <ContactLeft>
          <TextBlack>
            {item.full_contact_person_info?.fullName
              ? item.full_contact_person_info.fullName
              : item.first_name
                ? item.first_name + ' ' + item.last_name
                : homeT('noName')}
          </TextBlack>
          <Social>
            {item.twitter && (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://twitter.com/` + item.twitter}
              >
                <Twitter />
              </a>
            )}
            {item.linkedin && (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.linkedin.com/` + item.linkedin}
              >
                <Linkedin />
              </a>
            )}
          </Social>
        </ContactLeft>
        <ContactCenter>
          {item.position ? (
            <TextLightExtraSmall>{item.position}</TextLightExtraSmall>
          ) : (
            <TextLightExtraSmall>{homeT('noPosition')}</TextLightExtraSmall>
          )}
          <div>
            <Flex>
              <Mail />
              {item.value ? (
                <TextAccentExtraSmall>{item.value}</TextAccentExtraSmall>
              ) : (
                <TextLightExtraSmall>{homeT('noMail')}</TextLightExtraSmall>
              )}
            </Flex>
            <Flex>
              <Cell />
              {item.phone_number ? (
                <TextAccentExtraSmall>{item.phone_number}</TextAccentExtraSmall>
              ) : (
                <TextLightExtraSmall className="phone">
                  {homeT('noPhone')}
                </TextLightExtraSmall>
              )}
            </Flex>
          </div>
        </ContactCenter>
        {(integrations?.find(
          (e) => e.title.includes('SugarCRM')
        )?.connected || integrations?.find(
          (e) => e.title.includes('Hubspot')
        )?.connected && enabled ) && (
          <ContactRight onClick={handleShow(idx)}>
            {showHubspot && <Send />}
            {show[idx] && (
              <Manage>
                <Box>
                  {integrations?.find(
                    (e) => e.title.includes('SugarCRM')
                  )?.connected && (
                    <>
                      <Flex>
                        <TextBlackThin onClick={handleContact(idx)}>
                          {homeT('sendContact')}
                        </TextBlackThin>
                        <ContactIcon />
                      </Flex>
                      <Flex>
                        <TextBlackThin onClick={handleLead(idx)}>
                          {homeT('sendLead')}
                        </TextBlackThin>
                        <ContactIcon />
                      </Flex>
                    </>
                  )}
                  {integrations?.find(
                    (e) => e.title.includes('Hubspot')
                  )?.connected && enabled && (
                    <Flex>
                      <TextBlackThin onClick={handleHubspot(idx)}>
                        {homeT('sendHubspot')}
                      </TextBlackThin>
                      <Hubspot />
                    </Flex>
                  )}
                </Box>
              </Manage>
            )}
          </ContactRight>
        )}
        {modals[idx] && (
          <>
            <Modal>
              <Overflow onClick={handleModalOff}></Overflow>
              <Popup>
                <SendContact
                  handleOpenAddOff={handleModalOff}
                  name={
                    item.full_contact_person_info?.fullName
                      ? item.full_contact_person_info.fullName
                      : item.first_name
                        ? item.first_name + ' ' + item.last_name
                        : homeT('noName')
                  }
                  mail={item.value}
                  number={item.phone_number}
                  job={item.position}
                  contact={contact}
                  lead={lead}
                  id={id}
                  hubspot={hubspot}
                />
              </Popup>
            </Modal>
          </>
        )}
      </Contact>
    );
  }

  function handleText(e) {
    setQuery(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSearch(!search);
  }
  
  const getContacts = async () => {
    await network
      .get(`/api/lead/${id}/contacts`, { params: {
        query
      } })
      .then(({ data }) => {
        setContactInfo(data.results);
      })
      .catch((error) => console.log(error));
  };

  const getContactsFilter = async () => {
    await network
      .get(`/api/lead/${id}/contacts`, { params: {
        name: selectedFilters.includes('name'),
        position: selectedFilters.includes('name'),
        phone_number: selectedFilters.includes('name'),
        linkedin: selectedFilters.includes('linkedin'),
        twitter: selectedFilters.includes('twitter')
      } })
      .then(({ data }) => {
        setContactInfo(data.results);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getContacts();
  /* eslint-disable */
  }, [id, search])
  /* eslint-enable */
  const [openFilter, setOpenFilter] = useState(false);

  function handleFilter() {
    setOpenFilter(true);
  }

  const handleFilterOff = () => {
    setOpenFilter(false);
  };

  useEffect(() => {
    getContactsFilter();
    /* eslint-disable */
  }, [selectedFilters]);
    /* eslint-enable */

  const [suggest, setSuggest] = useState(false);

  function handleSuggest() {
    setSuggest(true);
  }

  function handleShowModalOff() {
    setSuggest(false);
  }

  return (
    <Wrap className={ctx.isLiteFree ? 'transparent' : '' }>
      {ctx.isLiteFree ? (
        <Wrapper>
          {homeT('upgradeContacts')}
          <FilledLink href='/company'>{homeT('upgradeNow')}</FilledLink>
        </Wrapper>
      ) : (
        <>
          {contactInfo?.length > 0 ? (
            <TopWithContacts>
              <Filter onClick={handleFilter}>
                <Filters />
                <TextBlackThin> {homeT('applyFilters')} </TextBlackThin>
                
              </Filter>
              <Search handleText={handleText} handleSubmit={handleSubmit} />
              {openFilter && (
                <>
                  <Modal onClick={handleFilterOff}></Modal>
                  <ContactFilter setSelectedFilters={setSelectedFilters} />
                </>
              )}
            </TopWithContacts>
          ): 
            <Top className={open ? 'open column' : 'column'} >
              <Button onClick={handleOpen}>
                <In />
                {homeT('findContacts')}
              </Button>
              {!companyInfo?.company_info?.website && !suggest && (
                <TextBlackThin>
                  {success ? 'Your message sent successfully!' : (<>
                    {homeT('please')}
                    <span onClick={handleSuggest}>{homeT('suggestDomain')}</span>
                    {homeT('weWill')}
                  </>)}
                </TextBlackThin>
              )}
            </Top>}
          {contactInfo?.length > 0 && contactInfo.map(renderContacts)}
          {open && (
            <>
              <Modal onClick={() => setOpen(false)}></Modal>
              <ModalBtn>
                <PopupBtn>
                  <Links>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={`https://www.linkedin.com/search/results/people/?company=${companyInfo?.company_name
                        ?.toLowerCase()
                        .replace(/ /g, '-')}`}
                    >
                      {homeT('findPeople')}
                    </a>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={`https://www.linkedin.com/sales/search/people?companyIncluded=${companyInfo?.company_name
                        ?.toLowerCase()
                        .replace(/ /g, '-')}&companyTimeS`}
                    >
                      {homeT('findSales')}
                    </a>
                  </Links>
                </PopupBtn>
              </ModalBtn>
            </>
          )}
          {suggest && (
            <Popup onClick={(e) => e.stopPropagation()}>
           
              <Suggest
                handleShowModalOff={handleShowModalOff}
                id={id}
                setSuccess={setSuccess}
                suggest={'domain'}
              />
            </Popup>
          )}
        </>
      )}
    </Wrap>
  );
}

Contacts.propTypes = {
  contactsInfo: any,
  companyInfo: object,
  integrations: any,
  id: number,
  enabled: object
};
